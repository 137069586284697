/**
 * Create chart data for device sensor
 */
async function sensorChartData(device, sensor, stage)
{
    return new Promise((resolve) =>
    {
        $.ajax
        ({
            url: BoilerBuddy_front_device_data,
            method: "GET",
            dataType: "json",
            data: {option:'sensorChartData', stage:stage, device:device, sensor:sensor},
            success: function(data) {
                resolve(data);
            }
        });
    });

}