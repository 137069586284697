/**
 *  Javascript file for BoilerBuddyDashboardPage
 */

/**
 * Dashboard page events
 */
$( document ).ready(function()
{
    const PageType = $('#PageType').val();
    if(PageType === '\\BoilerBuddy\\PageTypes\\BoilerBuddyDashboardPage') {
        /**
         * Elements
         */
        const el_stage_select = $("select[data-bb-stage-select]");
        const el_device_select = $("select[data-bb-device-select]");

        /**
         * Selected stage
         * If not set in parameter get value from select element
         */
        let selectedStage = getUrlParameter('stage');
        if(!selectedStage) {
            selectedStage = 2;
        }
        el_stage_select.val(selectedStage);


        /**
         * Get selected device and load dashboard charts
         */
        let selectedDevice = el_device_select.val();
        loadDashboardCharts(selectedStage, selectedDevice);


        /**
         * Device select on change
         */
        el_device_select.change(function() {
            window.location.href = window.location.href.split("?")[0] + "?device=" + $(this).val() + "&stage=" + el_stage_select.val();
        });

        /**
         * Stage select on change
         */
        el_stage_select.change(function() {
            window.location.href = window.location.href.split("?")[0] + "?stage=" + $(this).val() + "&device=" + el_device_select.val();
        });
    }
});


/**
 * Load dashboard charts on base of selected device and stage
 * @param stage
 * @param device_id
 * @param append_id
 * @returns {Promise<boolean>}
 */
async function loadDashboardCharts(stage = 2, device_id = 0, append_id = "charts")
{
    //Clear current append_id content
    $('#' + append_id).html('');

    //Check device ID
    if(!device_id) {
        device_id = $("select[data-bb-device-select]").val();
        if(!device_id) {
            returnNotification("Oeps", "Geen device geselecteerd.", 'danger');
            return false;
        }
    }

    //Get device info
    const device = await deviceInfo(device_id);

    //Get device sensors
    const device_sensors = await deviceSensors(device.id);

    //Check if sensors are not empty
    if(device_sensors.length <= 0) {
        return false;
    }

    //Get chart type based on selected stage
    //Bar chart is only used in stage 4 (per day)
    const chartType = (parseInt(stage) === 4 ? 'bar' : 'line');

    //Loop over sensors and create chart
    for (const sensor of device_sensors) {
        //Get chart data for sensor
        let chartData = await sensorChartData(device_id, sensor.id, stage);

        //Create chart
        if(parseInt(chartData.error) === 0) {
            await createChart(chartData.sensor + '_chart', chartData, append_id, 'col-lg-6 col-md-12', chartType);
        }
    }

    //Remove loader when all charts are loaded
    $('#chartsLoader').remove();
    return true;
}