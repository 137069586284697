//Variable with all active charts
let BoilerBuddy_front_active_charts = [];

/**
 * Create line data set
 * @param data
 * @param dataset
 * @returns {{mode: string, name: string, x, y, type: string, fill: string}}
 */
function lineDataset(data, dataset) {
    return {
        type: 'scatter',
        mode: 'lines+markers',
        name: dataset['name'],
        x: data['time_labels'],
        y: dataset['data'],
        fill: 'tozeroy',
    };
}

/**
 * Create bar data set
 * @param data
 * @param dataset
 * @returns {{name, x, y, type: string}}
 */
function barDataset(data, dataset) {
    return {
        type: 'bar',
        name: dataset['name'],
        x: data['time_labels'],
        y: dataset['data']
    };
}


/**
 * Create chart from chart data
 * @param canvas_id
 * @param data
 * @param append_div_id
 * @param div_class
 * @param type
 * @param begin_at_zero
 * @param bezier_curve
 */
async function createChart(canvas_id, data, append_div_id, div_class = 'col-lg-6 col-md-12', type = 'line', begin_at_zero = true, bezier_curve = true)
{
    return new Promise((resolve, reject) => {
        //Variable for chart data
        let chartData = [];

        //Create chart data
        if (type === 'line') {
            //Line chart
            $.each(data['datasets'], function (key, dataset) {
                chartData.push(lineDataset(data, dataset));
            });
        }
        else {
            //Bar chart
            $.each(data['datasets'], function (key, dataset) {
                chartData.push(barDataset(data, dataset));
            });
        }

        //Create X axis angle
        let xAxisAngle = 0;
        if (data['datasets'][0]['data'].length > 20 || $(window).width() <= 1300) {
            xAxisAngle = 60;
        } else if (data['datasets'][0]['data'].length > 10) {
            xAxisAngle = 20;
        }

        //Chart layout options
        const layout = {
            //Chart title
            title: data['sensor'] + " - " + data['datasets'][0]['label'],

            //Chart autosize for responsive view
            autosize: true,

            //Show legend & orientation
            showlegend: true,
            legend: {
                "orientation": "h",
                y: -0.3
            },

            //Chart X axis angle
            xaxis: {
                tickangle: xAxisAngle
            },

            //Chart margin
            margin: {
                l: 20,
                r: 20,
                b: 40,
                t: 80,
                pad: 4
            }
        };

        //Chart config
        const config = {
            responsive: true
        };

        //Create chart HTML holder
        $('#' + append_div_id).append("" +
            "<div class='" + div_class + " mb-3'>" +
            "   <div id='" + canvas_id + "_holder' class='chart-holder'>" +
            "       <div id='" + canvas_id + "' class='chart-canvas-plotly'></div>" +
            "   </div>" +
            "</div>");

        //Create chart in HTML holder
        Plotly.newPlot(canvas_id, chartData, layout, config);
        resolve(true);
    });
}



