/**
 * Get device info
 * If no ID supplied current selected device will be used
 * @param device
 * @returns {Promise<unknown>}
 */
async function deviceInfo(device = 0)
{
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            url: BoilerBuddy_front_device,
            method: "GET",
            dataType: "json",
            data: {option:'deviceInfo', device:device},
            success: async function(data)
            {
                resolve(data);
            }
        });
    });
}

/**
 * Get device sensors
 * @param device
 * @returns {Promise<unknown>}
 */
async function deviceSensors(device)
{
    return new Promise((resolve, reject) =>
    {
        $.ajax
        ({
            url: BoilerBuddy_front_device,
            method: "GET",
            dataType: "json",
            data: {option:'deviceSensors', device:device},
            success: async function(data)
            {
                resolve(data);
            }
        });
    });
}